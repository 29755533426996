import React from 'react'

import './small-leader-board.scss'

const SmallLeaderBoard = () => {
  return (
    <div>
      
    </div>
  )
}

export default SmallLeaderBoard