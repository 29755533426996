import React from 'react'

const Winner = ({ fillColor, className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1257.41 1033" fill={fillColor} className={className}>
      <path d="M971.4,20.9c3.8,3.8,5.3,6,4.5,6.3-1.9.6,1.7,5.7,12.2,17.3,18.4,20.4,28.1,32.9,44,56.5,5.7,8.5,13.6,19.3,17.5,24,10.7,12.9,33.8,34,46.3,42.4,5.3,3.6,8.9,4.7,7.4,2.3-.9-1.4,2.7.8,12,7.5,3.7,2.6,6.7,4.7,6.7,4.4s-1.3-3.3-3-6.9c-4.8-10.6-10.7-26.8-16.1-44.6-2.8-9.1-6.5-20-8.1-24.1-12.1-29.6-31.6-46.5-81.3-70.6C996,27,968.4,15,966.4,15,965.9,15,968.2,17.6,971.4,20.9Z" transform="translate(-14.45 -15)" />
      <path d="M318,24.7c-14,5.8-21.1,8.3-20.5,7.2,2.2-3.5-47,20.9-62.2,30.9-20.2,13.4-33.8,28-41.1,44.3-6.5,14.6-22.5,64.4-21.7,67.6.3,1.3,1,2.1,1.5,1.8,1.5-.9,1.2.9-1,6.1-1.1,2.6-1.9,4.9-1.7,5.1.6.6,23.3-16.6,36.2-27.4,12-10,25.9-23.8,32.7-32.3,1.9-2.5,8.8-12.4,15.3-22,18.7-27.7,28.6-39.9,55.2-67.8,9-9.4,16.3-17.2,16.1-17.1S322.7,22.8,318,24.7Z" transform="translate(-14.45 -15)" />
      <path d="M1095,78.6a47,47,0,0,0,3.8,6.2c5.7,8.4,6,9,3.7,7.2-3.9-3.1-.6,4.9,7.4,18.4,13.3,22.4,19.3,35.7,23.5,52.1,2.7,10.7,8.9,53,8.4,57.2-.3,2.2-1.3,1.2-8.7-8.1-11.4-14.5-26-28.1-39.8-37.2-6.2-4.1-16.4-10.8-22.7-14.9-13.3-8.7-26-20.3-34.2-31.3-3.2-4.2-6.2-8.1-6.6-8.6-.7-.9-.4,4.3.7,13.3.4,3,.3,3.2-.9,1.6-3.7-5-3.7,7.5-.1,20.1,9.8,33.5,24,49.8,65.5,74.9,20,12.1,29.1,18.6,38.8,28,10.5,10,16.3,17.3,24.1,30.2,6.7,11.1,10.1,14.6,11.2,11.6.5-1.5,4,5.6,7.8,16,2,5.4,4.5-39.6,4.6-81.3,0-38.7-.8-48.7-5.6-67.5-7.5-29.3-23.4-50.9-52.1-70.6C1114.3,89.4,1095,77.8,1095,78.6Z" transform="translate(-14.45 -15)" />
      <path d="M186.1,91.5c-6.3,3.6-11.6,6.5-11.9,6.5s0-.5.3-1.1c1.5-2.4-11.7,5.4-20,11.9-23.7,18.3-37.4,38.5-43.9,64.8-3.3,13.1-4.3,20.7-5.7,40.4-1.7,25.4.5,82.3,3.6,89.5,1.5,3.7,3.4,4.6,3.6,1.7.3-3.8,1.7,4.7,1.8,10.9,0,3.6.4,6.9.8,7.2s1.6-2.2,2.9-5.7c2.9-8.4,10.5-23.5,15.6-31.1,12.4-18.3,27.9-32.1,53.5-47.5,32.5-19.4,45.9-30.4,56.4-46.4,9.3-14.1,16-33.4,18.9-54.6.6-4.7,1.3-9.3,1.5-10.3.5-2.9-.9-1.9-3.7,2.6-2.4,3.7-11.2,13.1-9.4,9.9,2.2-3.7-4.5,1-15.3,11a159.6,159.6,0,0,1-18.5,14.4c-5.5,3.6-15.5,10-22.1,14.3A162.71,162.71,0,0,0,173.7,196c-8.2,7.7-26.5,28.4-29.5,33.3-1.3,2-1.3,1.9-.8-1.3.4-1.9,2.2-14.4,4.1-27.6s4.5-27.7,5.6-32c5.4-20.7,16.8-42.9,36.3-70.7,8.4-12,8.9-12.7,8.4-12.7C197.6,85,192.4,87.9,186.1,91.5Z" transform="translate(-14.45 -15)" />
      <path d="M1191.9,169.2c2.9,6.4,5.1,14.6,3.2,11.9-2.1-3.1-2.3,4.3-.6,16.8,2.7,18.8,2.3,36-1.9,73.1-2,17.3-4.3,41.8-5.1,54.4s-1.7,23.1-1.8,23.3-1.8-3.2-3.6-7.5c-4.4-10.7-12.1-22.5-19.5-29.7-3.4-3.3-13.8-11.2-23.1-17.5-25.7-17.5-37.4-30.2-50-54.5l-4.6-9,.6,7c.3,3.8.8,9,1.1,11.5s.2,3.9-.2,3.2c-1-2.2-2.3-1.3-2.9,2-1.5,7.8,4.9,31.2,13.5,49.4,8,17,16.8,29.4,44.4,62,19.4,23.1,30.9,42.5,40.1,67.9,5.2,14.4,5.7,15.6,8.2,18,1.6,1.6,1.7,1.6,2.3-.1.4-1.2,1.5,1.6,3.1,8l2.4,9.7,16.2-64.8c16.8-66.9,20.2-82.3,22.7-101.5,3.6-27.2,3-46.3-1.9-65.9-6.1-24.4-19-46.7-39.7-68.7a63.6,63.6,0,0,0-5.3-5.2C1189.3,163,1190.4,165.8,1191.9,169.2Z" transform="translate(-14.45 -15)" />
      <path d="M97.4,175.2c-2.7,2.9-6.8,7.5-9.2,10.2s-4,4.2-3.6,3.3c1.1-2.9-1.4-1.9-5.7,2.4-5.2,5.2-13,17.2-17.9,27.4-15.2,32-16.8,65.6-5.9,120,2.9,14.2,21,87.3,26.6,107.2,2.7,9.7,6.1,15.2,7.3,11.8.4-1.2,1.3.6,2.8,5.6a65.38,65.38,0,0,1,2.2,9.1,3.11,3.11,0,0,0,1,2.3c.6.3,1-.2,1-1.2,0-2.9,5.7-24.4,9.1-34,8.9-25.6,20.7-44.6,46.8-75.1,23.7-27.9,34-43.5,42-63.7,6.4-16.2,11.6-38.5,13.6-58l.6-6-3.9,7.5c-4.2,8.1-8.9,16-9.7,16-.2,0-.2-.7.2-1.6,1.4-3.6-3.8.8-10.9,9.4-4,4.8-9.9,11.3-13.3,14.6-5.4,5.3-9.8,8.6-31.8,24-11.1,7.8-19.2,15.2-23.1,21-4.9,7.5-14.4,25.2-14.8,27.9-.2,1.2-.7-3.2-1.1-9.8-1.7-29.4-3.1-44.6-5.8-67.5-3.8-31.3-4.7-56.6-2.5-70.2,1.7-10.4,6.5-26.7,9.8-33.1,1.1-2.2,1.8-4.1,1.5-4.3S100.1,172.3,97.4,175.2Z" transform="translate(-14.45 -15)" />
      <path d="M1256,330.4c0,3.9-3.3,15.8-4,14.6-1.3-2.1-4.6,2.2-6.3,8.1-.8,3-3.5,14-6.1,24.4-8,32.9-14.2,50.9-27,78.5-11.1,23.6-22.6,52.5-22.6,56.5,0,4.2-1,.2-2.5-9.6-5-33.4-18.2-64.2-54.8-127.4-6-10.5-11.4-19.5-11.9-20s-.8,2-.7,7c.2,5.7-.1,8-1,8-3.5,0-4,10-1.6,31,4.5,38.6,10.2,64.3,23.5,105,12.8,39.1,14.3,47.9,14.2,83.4-.1,25.5.4,29.8,3.4,28.7,1.2-.4,1.3.5.8,5.2l-.5,5.7,8.2-8.5c57.1-59.3,89.3-110.7,100.3-160.2,3.9-17.7,4.9-30,4.3-51.3-.6-19.3-1.9-29.3-6.2-49S1256,324.5,1256,330.4Z" transform="translate(-14.45 -15)" />
      <path d="M32.6,345.2c-2,6.4-3,8.7-3.5,7.5-.6-1.8-.7-1.8-2.3,0-2.6,2.8-4.4,7.8-6.6,18.3-14,65.3-2.6,122.4,36.1,180,21.2,31.5,58.8,75,65,75,.5,0,.5-.6.1-1.3s2.1,1.5,5.4,4.7,6.3,5.8,6.5,5.5-.2-6.3-.9-13.4c-1.6-16.1-1.7-42.4-.4-55.3,1.5-13.7,4.9-27.2,13.6-53.7,11.2-34.2,16.3-55,20.8-85,2.7-18.1,7.2-65.3,6.3-66.2-.2-.2-2.8,3.8-5.7,8.7s-5.6,8.6-6,8c-2.2-3.6-9.7,7-25.1,35.5-21.5,39.8-29.9,60.9-36,90.5-1.7,8-3.2,14.6-3.3,14.7a71.65,71.65,0,0,1-3-8.5c-4.3-13.3-9.5-26-18.9-45.8a361.42,361.42,0,0,1-23.2-61.7c-4.9-18-11.6-47.1-13.6-59.3-.7-4.1-1.5-7.4-1.9-7.4S34.2,340.2,32.6,345.2Z" transform="translate(-14.45 -15)" />
      <path d="M1120.7,509.8c-2,3.7-3.7,6.1-3.7,5.4,0-2.4-3.9.7-6.1,4.9-2.7,5.3-7.4,23.8-9.3,36.4-2.2,14.5-3.3,25.7-5.6,56-4.8,64.3-9.3,90.8-23,137.1-3.2,10.8-3.6,17.5-.8,15.8,1.1-.7,1-.3-.9,6.5-.6,2.5-.6,2.5,5.3-.8,12.9-7.3,48.8-30.2,64.9-41.5,55.2-38.7,86.7-71.5,105.6-110.1,13.4-27.5,19.9-56.7,19.9-89.5V515.5l-4.7,8.8c-2.6,4.8-5,8.2-5.3,7.7-1.5-2.4-6.3,3.8-15.5,19.8-21.1,36.3-32.1,49.6-62.5,75.2-9.6,8.1-19.1,16.5-21.1,18.6-5.3,5.8-19.4,22.6-20.8,24.9l-1.2,2,.5-2c4.3-16.3,5.7-53,3.2-77.5-2.6-24.3-12.5-85-14.5-88.7C1124.7,503.5,1123,505.6,1120.7,509.8Z" transform="translate(-14.45 -15)" />
      <path d="M167,510.9c0,3.9-2.4,12.1-3.1,10.9-1.7-2.7-4.8,2.3-6.8,11.1-4.8,20.6-10.6,64-11.6,87.1-.7,13.4-.4,19.6,2.5,49,.4,4.1.6,7.6.6,7.7s-2.8-3.5-6.1-7.9c-9.3-12.6-17.7-21-34.5-34.8-32.7-26.8-49.7-48.9-73.4-95-7.8-15.2-8.4-16-9-13a87.21,87.21,0,0,0-.7,8.9c0,3.4-.3,4.9-.8,3.8-.6-1.8-.8-1.8-2.1-.2-3.3,4-2.2,23.6,2.6,45.4,13.2,59.5,51.7,106.2,131.6,159.9,26.5,17.7,44.3,28.3,48.1,28.6,1.8.2,6.5,2.1,10.3,4.4s7,3.9,7.1,3.9-2.5-8.2-5.8-17.9C201.8,720.9,195.5,686,191,626c-4.8-64.2-8.7-86.7-19-108.8C168.1,508.8,167,507.5,167,510.9Z" transform="translate(-14.45 -15)" />
      <path d="M1075.7,637c-5.5,7.1-8.1,9.8-9.2,9.3-3.2-1.2-15.5,15.1-30.4,40.4-13.1,22.3-19.2,36.8-28.2,67.3-7,23.6-11.5,36.2-17.1,47.5a323.5,323.5,0,0,1-25.1,40.7c-13.8,19.2-14.9,21-14.4,23.7a6.94,6.94,0,0,1-1.5,5.3c-1,1.6-1.5,2.8-.9,2.8,1.5,0,43.2-10.1,63.6-15.4,71.9-18.8,116.1-35.3,143.9-53.8,9.6-6.4,22.3-18.1,28.7-26.6,6.7-8.9,14.2-24,18.9-37.9,4.1-12.5,17.4-62.2,16.8-62.9-.3-.2-3,3.6-6.1,8.4-6.1,9.3-11.1,15.8-10.1,12.9,1.8-5.1-5.6,2-17.8,17.3-23.8,29.7-40.1,41.3-81.8,58-19.3,7.8-27.5,11.6-39.5,18.6-5.5,3.1-12.4,7-15.4,8.6a50.37,50.37,0,0,0-6.8,4c-.8.7.9-3.5,3.9-9.3,10-19.4,12.9-31.4,18.3-76.4,4.7-39.4,9.2-62.8,16.9-87.8.8-2.6,1.4-4.7,1.2-4.7S1079.9,631.5,1075.7,637Z" transform="translate(-14.45 -15)" />
      <path d="M209,633.7c0,.4,1.2,4.6,2.5,9.2,2.6,8.9,3.2,12.8,1.6,10.2-.7-1.1-1.1-1-2,.7-.8,1.5-.7,4.1.3,9.9,5.2,28.9,6.9,39.6,9.6,62.3,5,41.3,6.8,50.1,14.1,67.9,2.2,5.3,4.4,11.3,4.8,13.4l.9,3.8-2.6-1.8c-16.1-10.6-33.9-19.7-57.7-29.3C147,766.6,134,758.8,117,742c-11.7-11.5-23.1-25.8-35.7-44.8C76,689.4,71.6,683,71.5,683s.7,4.2,2,9.2,2.6,10.6,2.9,12.3l.6,3L75.6,705l-1.4-2.5-.7,2.8c-1.1,4.3.2,11.4,5,28.3,8.9,31,16.5,45.3,32.5,61.4,15.6,15.7,34.4,27.2,64.5,39.3,14.5,5.9,47.4,16.9,48.5,16.2a.78.78,0,0,1,1.2.4c.1.5,1.7,1.1,3.3,1.3s3.2.6,3.5,1a9.22,9.22,0,0,0,3.5,1c1.7.2,3.2.6,3.5,1a9.22,9.22,0,0,0,3.5,1,8.22,8.22,0,0,1,3.6,1c.3.4,1.8.9,3.5,1.1a13.48,13.48,0,0,1,4.1,1,12.28,12.28,0,0,0,4,1.1c1.5.1,16.5,3.7,33.3,7.9,27.6,6.8,53.2,12.7,53.7,12.3.1-.1-3.2-4.4-7.3-9.6s-11.3-14.7-15.8-21c-22.6-31.5-31.7-49.9-41.6-84.5-9.3-33-17.2-51.7-31.7-75.9C237.9,672.4,209,631.1,209,633.7Z" transform="translate(-14.45 -15)" />
      <path d="M995.9,757.4c-2.7,2.5-21.3,15-20.5,13.8,4.4-7.1-31.4,18.6-58.9,42.2-3.8,3.3-16.4,15.3-28,26.6-27.3,26.8-31.6,29.5-76.6,47.1-9.9,3.9-17.7,7.4-17.5,7.8s-1.7,1.6-4.5,2.6-4.9,2.3-4.9,2.6,1.2,1.2,2.8,1.7,2.1,1.1,1.4,1.1-1,.5-.7,1.1,0,1-.7,1,0,.8,1.7,1.7,6.6,2,11,2.4a180.49,180.49,0,0,1,17.8,3.3c5.4,1.4,10.6,2.2,11.5,1.9,1.1-.5,1.3-.3.7.7s-.2,1.1,1.9.6c1.4-.3,2.6-.3,2.6.2s1.7.9,3.7.9,3.9.6,4.1,1.2.7.9,1.2.6,6.4.8,13.2,2.4c54.1,13,99.8,17.7,132.3,13.6,19.3-2.4,31.7-6,48-14,20.2-10,35.4-23.1,75.5-65.4,10.2-10.7,18-19.2,17.5-19s-7.5,4.7-15.5,10c-16.9,11.2-25.1,16-23.1,13.5.8-.9-6.9,2.4-17,7.4s-22.1,10.3-26.6,11.9-7.9,3.2-7.5,3.6.2.5-.4.2-2.7-.1-4.5.7c-9.2,3.8-21.4,4.8-69.9,5.7-26.1.5-49.2,1.2-51.2,1.5a27.36,27.36,0,0,1-8.8-.6l-5.1-1.2,6.8-4.1c13.9-8.3,22.8-16,31.6-27.4,7-9.1,10.3-14.8,27.6-46.7,8.9-16.4,19.6-35.2,23.7-41.9C997.8,757.2,999,754.5,995.9,757.4Z" transform="translate(-14.45 -15)" />
      <path d="M295,763.4a80.25,80.25,0,0,0,4.1,7.3c2.3,3.8,4.4,7.2,4.7,7.8s-.2.4-1.1-.4c-3.6-2.9-1.7,3.9,4.2,15.2,15.6,29.9,28,52.2,34.6,62.2,7,10.6,11.5,15.3,30.6,32.8,4.8,4.3,6.1,6,5,6.6s-1.1,1,0,1.5c.8.3-2.2.3-6.6,0s-28.2-1-53-1.4c-48-.9-52.9-1.3-70.5-6.6-21.4-6.5-47.6-20.2-75.3-39.6-5.4-3.7-10.1-6.8-10.5-6.8s3.6,4.4,8.8,9.7c10.7,11,17.6,18.7,14.3,15.9-2.5-2.1-2.9-1.3-1,2.1,2.4,4.4,16.2,19.6,25.6,28.2,12.8,11.8,26.1,21.7,36.9,27.4,10,5.3,21.9,10,23.2,9.2a.8.8,0,0,1,1.2.4c.1.6,1.9,1.2,3.8,1.4s8,1.4,13.5,2.6c42.5,8.8,101,1.9,189-22.4,17-4.8,30.2-8.8,31-9.7.3-.2-3.4-1.9-8.3-3.8-39.4-14.8-65.1-26-76.7-33.5-2.7-1.7-14.4-12.6-26-24.2C370.1,819,347.3,800,310,773.5c-6.9-4.8-13.1-9.3-13.7-9.8S295,763,295,763.4Z" transform="translate(-14.45 -15)" />
      <path d="M706.5,890.2c-10.2.6-28.7,3.8-34.3,5.9-1.3.5-2.7.7-3.2.4-1-.6-15.4,4.1-25.8,8.3l-7.3,3-13.7-3.9c-30.8-8.9-54.1-10-79.7-3.8-4.4,1.1-9.6,2.2-11.5,2.4a94.32,94.32,0,0,0-12,3.6c-8.2,3-11.1,3.8-26.4,7.3-4.4,1-7.4,2.3-8.3,3.6-2.8,4-25.3,14.7-45.5,21.6-5.7,1.9-11,3.8-11.8,4.1a7.28,7.28,0,0,1-2.9.4c-.8-.1-1,.2-.5,1s.2,1-1.1.6a3.23,3.23,0,0,0-2.9.2c-.6.5-4.9,1.8-9.6,2.9-35.3,8.4-83.7,9.4-134.2,2.9-5.4-.7-9.8-.9-9.8-.5a.79.79,0,0,0,.8.8c.4,0,2.7,1.9,5.2,4.1s3.8,3.7,3,3.4.3,1.1,2.5,3.3c12.2,12.4,69.4,42.2,84,43.7,1.7.2,6.8,1.3,11.5,2.4,14.6,3.4,24.5,4.4,38.4,3.8,29.5-1.3,53.1-11.3,90.4-38.3,41.1-29.8,60.6-40.8,77.8-44,2.9-.5,3.4-.3,3.4,1.3,0,3.1-5.1,7.1-32.5,25.5-36.1,24.2-75.6,53.3-79,58.1a11.32,11.32,0,0,0-1.5,5.3c0,5.5,9.6,16.4,14.5,16.4,1.4,0,3.1,1.1,4.3,2.7l2,2.7,16.9-16.5c20.6-20,36.4-34.1,54.9-48.9,22.5-18,51.1-36.3,66.3-42.6l5.8-2.3,7.4,2.5c11.9,4,34.3,15.6,49.3,25.4,26.9,17.7,47.5,34.5,81.1,66.5,16.2,15.4,19.7,18.1,21.6,16.9.6-.3,2.5.8,4.3,2.5l3.2,3.1,9.8-11.6c5.4-6.4,9.4-11.9,9-12.4s-7.2-5.6-14.9-11.5c-44.5-34.2-90.4-65-122.8-82.4-5-2.8-9.9-5.6-10.7-6.3s-1.3-1.2-1.1-1.1,1-.5,1.6-1.4c1.1-1.4,3.1-1.7,13.1-1.7,11.5.1,23.2,1.6,24.3,3.2.3.4,2.6,1.4,5.1,2.1,12.9,3.7,30.8,14.1,57.6,33.2,22.7,16.4,36.2,25.3,46.4,30.7,9.7,5.2,25,11.4,26.7,10.8.7-.3,1.3-.1,1.3.5s.4.8.8.5,3.3.1,6.3.9c15.7,4.1,36.7,4.8,53.5,2,24.8-4.3,53.8-16.5,84.4-35.7,9.7-6.1,35.1-24,34.7-24.4-.1-.2-3.4.1-7.2.6-42.6,5.3-87.2,5.6-118.2,1-33.4-5.1-59-13.3-101.3-32.5C759.5,894.1,735.6,888.4,706.5,890.2Z" transform="translate(-14.45 -15)" />
      <path class="cls-1" d="M924.38,204.68l.92.92c-6.73-4-16.11-5.35-27.83-5.35-50.06,0-104.15,33.84-115.61,84.56-6.84,8.52-14.63,13.92-23.45,16.16-25.44,6.53-39.36-10.65-58.68-22.27-10.7-6.43-21.4-11-33.84-12.85a91,91,0,0,0-28.55.21C525.71,284,529.12,390.94,585,462.72c16.31,20.95,35.53,41.44,52.45,61,17.39,20.09,34.72,38.64,38.44,57.3,5.15,25.79-15,45.16-39,39.91-21.41-4.69-49.8-21.2-80.28-40.27l-109.9-63.46c-20.39-12.59-39.56-17.69-57.45-14.68-33.24,5.6-50.32,34.46-42.87,66.93,12,52.4,75.39,80.23,70.8,141-1.73,23.3-15.6,43.89-41.19,61.79q10.48.45,23.71-8.57c9.22-6.27,16.56-12.64,22.17-19.52,31.35-38.23,11.27-83.6-9.53-120.05-4.49-7.9-8.51-16.31-10.91-26.81-5.66-24.77,4.84-38.44,29.87-27.78C487.27,593.26,606,710.25,670.63,703.27c45-4.9,80.33-43.79,90.48-86.35,12.79-53.78-23.86-90.23-53.83-132.44-19.52-27.47-39.91-54.13-58.67-82.17-6-9.43-4.39-19.47,1.07-26.35,15.8-19.93,33.74.25,49.24,8.41a131.71,131.71,0,0,0,48,14.37c40.16,4.29,83.8-14.88,110.71-44.65,50.06-15,78.3-66.42,80.19-117.7.35-9.84-1.07-17.18-4.23-22.58l.15.15c9.69-9.28,24-15.45,37.36-19.22-8.56-2.65-17.07-4.13-25.43-3.31A56.73,56.73,0,0,0,944.52,166c-5.51,13.71-13.16,28.6-20.14,38.69ZM913.22,268.6a11.54,11.54,0,1,0,0,16.32,11.58,11.58,0,0,0,0-16.32ZM869.58,225a11.53,11.53,0,1,0,0,16.31,11.47,11.47,0,0,0,0-16.31Z" transform="translate(-14.45 -15)" />
    </svg>
  )
}

export default Winner