import React from 'react'

// import './snake-icon.scss'

const SnakeIcon = ({ className, fillColor }) => {
  return (
    <svg version="1.1" className={className} fill={fillColor} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="122.9px"
      height="118.9px" viewBox="0 0 122.9 118.9">
			
      <g>
        <path d="M113.7,7.6l0.2,0.2c-1.3-0.8-3.2-1-5.5-1c-9.8,0-20.4,6.6-22.7,16.6c-1.3,1.7-2.9,2.7-4.6,3.2
		c-5,1.3-7.7-2.1-11.5-4.4c-2.1-1.3-4.2-2.2-6.6-2.5c-1.8-0.3-3.6-0.3-5.6,0c-21.9,3.5-21.2,24.5-10.3,38.6c3.2,4.1,7,8.1,10.3,12
		c3.4,3.9,6.8,7.6,7.5,11.2c1,5.1-3,8.9-7.6,7.8c-4.2-0.9-9.8-4.2-15.8-7.9L20,68.9c-4-2.5-7.8-3.5-11.3-2.9
		c-6.5,1.1-9.9,6.8-8.4,13.1c2.4,10.3,14.8,15.7,13.9,27.7c-0.3,4.6-3.1,8.6-8.1,12.1c1.4,0.1,2.9-0.5,4.7-1.7
		c1.8-1.2,3.2-2.5,4.4-3.8c6.1-7.5,2.2-16.4-1.9-23.6c-0.9-1.6-1.7-3.2-2.1-5.3c-1.1-4.9,0.9-7.5,5.9-5.4
		c11,4.7,34.3,27.6,46.9,26.2c8.8-1,15.8-8.6,17.8-16.9c2.5-10.6-4.7-17.7-10.6-26c-3.8-5.4-7.8-10.6-11.5-16.1
		c-1.2-1.8-0.9-3.8,0.2-5.2c3.1-3.9,6.6,0,9.7,1.7c3.2,1.7,6.4,2.5,9.4,2.8c7.9,0.8,16.4-2.9,21.7-8.8c9.8-2.9,15.4-13,15.7-23.1
		c0.1-1.9-0.2-3.4-0.8-4.4l0,0c1.9-1.8,4.7-3,7.3-3.8c-1.7-0.5-3.3-0.8-5-0.7c0.3-1.7,0.2-3.3-0.2-5C116.6,2.7,115.1,5.6,113.7,7.6
		L113.7,7.6z M111.5,20.1c-0.9-0.9-2.3-0.9-3.2,0c-0.9,0.9-0.9,2.3,0,3.2c0.9,0.9,2.3,0.9,3.2,0C112.4,22.5,112.4,21,111.5,20.1
		L111.5,20.1z M103,11.6c-0.9-0.9-2.3-0.9-3.2,0c-0.9,0.9-0.9,2.3,0,3.2c0.9,0.9,2.3,0.9,3.2,0C103.9,13.9,103.9,12.5,103,11.6
		L103,11.6z"/>
      </g>
    </svg>
  )
}

export default SnakeIcon