import React from 'react'

// import './speed.scss'

const Speed = ({ fillColor, isDrop, setIsDrop, className }) => {
  return (
    <svg onClick={() => {
      setIsDrop(!isDrop)
    }}
    style={{cursor: 'pointer'}} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={fillColor} width="48px" height="48px">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20.38 8.57l-1.23 1.85a8 8 0 0 1-.22 7.58H5.07A8 8 0 0 1 15.58 6.85l1.85-1.23A10 10 0 0 0 3.35 19a2 2 0 0 0 1.72 1h13.85a2 2 0 0 0 1.74-1 10 10 0 0 0-.27-10.44zm-9.79 6.84a2 2 0 0 0 2.83 0l5.66-8.49-8.49 5.66a2 2 0 0 0 0 2.83z" />
    </svg>
  )
}

export default Speed