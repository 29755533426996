import React from 'react'

const Pause = ({ fillColor, setGameSpeed, className }) => {
  return (
    <svg onClick={() => setGameSpeed(null)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={fillColor} className={className} width="48px" height="48px">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
    </svg>
  )
}

export default Pause